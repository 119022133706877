import React from "react";

import styles from "./styles.module.scss";
import { Text } from "../../../core/constants/strings";
import { images } from "../../../core/constants/images";

const OurStoryTablet = () => {
  return (
    <div
      className={styles.textContent}
      style={{ backgroundImage: `url(${images.upperBackground})` }}>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          <div className={styles.title}>{Text.ourStory.title}</div>
          <div className={styles.paragraph}>{Text.ourStory.paragraph}</div>
        </div>
        <div className={styles.upper}>
          <img className={styles.firstImage} src={images.startedImage} />
          <img className={styles.secondImage} src={images.footerImageThree} />
        </div>
      </div>

      <div
        className={styles.container}
        style={{ backgroundImage: `url(${images.footerBackground})` }}>
        <div className={styles.middle}>
          <img className={styles.thirdImage} src={images.footerImageThree} />
          <div className={styles.logoContent}>
            <div className={styles.logoTitle}>{Text.footer.appName}</div>
            <img className={styles.logoImage} src={images.logoNMCP} />
          </div>
        </div>
        <div className={styles.lower}>
          <div className={styles.navi}>
            <img className={styles.icon} src={images.worldImage} />
            <div className={styles.iconText}>{Text.footer.webSite}</div>
          </div>
          <div className={styles.navi}>
            <img className={styles.icon} src={images.phoneImage} />
            <div className={styles.iconText}>{Text.footer.phoneNumber}</div>
          </div>
          <div className={styles.navi}>
            <img className={styles.icon} src={images.mailImage} />
            <div className={styles.iconText}>{Text.footer.eMail}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStoryTablet;
