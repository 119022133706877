import React from "react";

import styles from "./styles.module.scss";
import { images } from "../../../core/constants/images";
import DownloadButton from "../../components/DownloadButton";
import { Text } from "../../../core/constants/strings";

const DownloadMobile = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>{Text.download.title}</div>
        <div className={styles.paragraph}>{Text.download.paragraph}</div>
      </div>
      <div className={styles.downloadButtons}>
        <DownloadButton
          storeString={Text.downloadLinks.apple}
          source={images.appleLogo}
          text={Text.welcome.buttons.appleButton.text}
          title={Text.welcome.buttons.appleButton.title}
        />
        <DownloadButton
          storeString={Text.downloadLinks.google}
          source={images.googlePlayLogo}
          text={Text.welcome.buttons.googlePlayButton.text}
          title={Text.welcome.buttons.googlePlayButton.title}
        />
      </div>
    </div>
  );
};

export default DownloadMobile;
