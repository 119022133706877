import React from "react";
import CarouselMobile from "../../../mobile/blocks/CarouselMobile/CarouselMobile";
import DownloadMobile from "../../../mobile/blocks/DownloadMobile/DownloadMobile";
import FooterMobile from "../../../mobile/blocks/FooterMobile/FooterMobile";
import HowItWorksMobile from "../../../mobile/blocks/HowItWorksMobile/HowItWorksMobile";
import OurTeamMobile from "../../../mobile/blocks/OurTeamMobile/OurTeamMobile";
import StartedMobile from "../../../mobile/blocks/StartedMobile/StartedMobile";
import WelcomeMobile from "../../../mobile/blocks/WelcomeMobile/WelcomeMobile";

const FullPageMobile = () => {
  return (
    <>
      <WelcomeMobile />
      <StartedMobile />
      <CarouselMobile />
      <HowItWorksMobile />
      <OurTeamMobile />
      <DownloadMobile />
      <FooterMobile />
    </>
  );
};

export default FullPageMobile;
