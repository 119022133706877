import React from "react";

import styles from "./styles.module.scss";
interface Props {
  image: string;
  name: string;
  position: string;
  index: number;
  indexNumber: number;
}

const OurTeamCarousel = ({ image, index, name, position, indexNumber }: Props) => {
  return (
    <div className={styles.inner} style={{ transform: `translateX(-${index * indexNumber}%)` }}>
      <div className={styles.images}>
        <img className={styles.imageOurTeam} src={image} />
        <div className={styles.names}>
          <p className={styles.name}>{name}</p>
          <p className={styles.name}>{position}</p>
        </div>
      </div>
    </div>
  );
};

export default OurTeamCarousel;
