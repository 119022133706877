export const images = {
  logo: require("../assets/logoApp.svg").default,
  iphone: require("../assets/iPhone12Mockup1.svg").default, 
  welcomeBackground: require("../assets/welcomeBackground.svg").default,
  welcomeBackgroundMobile: require("../assets/welcomeBackgroundMobile.svg").default,
  appleLogo: require("../assets/appleLogo.svg").default,
  googlePlayLogo: require("../assets/googlePlayLogo.svg").default,
  leftArrowCarousel: require("../assets/leftArrowCarousel.svg").default,
  rightArrowCarousel: require("../assets/rightArrowCarousel.svg").default,
  startedImage: require("../assets/startedImage.svg").default,
  footerBackground: require("../assets/footerBackground.svg").default,
  footerImageOne: require("../assets/footerImageOne.svg").default,
  footerImageTwo: require("../assets/footerImageTwo.svg").default,
  footerImageThree: require("../assets/footerImageThree.svg").default,
  logoNMCP: require("../assets/logoNMCP.svg").default,
  worldImage: require("../assets/worldImage.svg").default,
  phoneImage: require("../assets/phoneImage.svg").default,
  mailImage: require("../assets/mailImage.svg").default,
  footerMobileBackground: require("../assets/footerMobileBackground.svg").default,
  howItWorksBackground: require("../assets/howItWorksBackground.svg").default,
  howItWorksPhone: require("../assets/howItWorksPhone.svg").default,
  upperBackground: require("../assets/upperBackground.svg").default,
  playButton: require("../assets/playButton.svg").default
};

export const ourTeam = {
  andreiStoica: require("../assets/andreiStoica.svg").default,
  norbert: require("../assets/norbert.svg").default,
  adrian: require("../assets/adrian.svg").default,
  andreiPop: require("../assets/andreiPop.svg").default,
  marcel: require("../assets/marcel.svg").default,
  sylvia: require("../assets/InczeSylvia.svg").default, 
  sorin: require("../assets/sorin.svg").default, 
  vlad: require("../assets/vlad.svg").default, 
  bogdan: require("../assets/bogdan.svg").default, 
  alin: require("../assets/alin.svg").default, 
  alexBirta: require("../assets/alexBirta.svg").default, 
  alexPetrusca: require("../assets/alexPetrusca.svg").default, 
  mihai: require("../assets/mihaiVenczel.svg").default, 
  catalin: require("../assets/catalinRacz.svg").default, 
};

export const carousel = {
  carouselLogin: require("../assets/carouselLogin.svg").default,
  carouselGenres: require("../assets/carouselGenres.svg").default,
  carouselHarryPotter: require("../assets/carouselHarryPotter.svg").default,
  carouselMyBooks: require("../assets/carouselMyBooks.svg").default,
};