import React, { useState } from "react";

import styles from "./styles.module.scss";
import { Text } from "../../../core/constants/strings";
import { ourTeamArray, ourTeamArray2 } from "../../../core/constants/arrays";

const OurTeamMobile = () => {
  const [team, setTeam] = useState<{ image: string; name: string; position: string }[]>(ourTeamArray2);
  const [handler, setHandler] = useState<boolean>(true);
  const [buttonText, setButtonText] = useState<string>(Text.ourTeamMobile.see);

  const buttonHandler = () => {
    if (handler) {
      setTeam(ourTeamArray);
      setHandler(false);
      setButtonText(Text.ourTeamMobile.hide);
    } else {
      setTeam(ourTeamArray2);
      setHandler(true);
      setButtonText(Text.ourTeamMobile.see);
    }
  };
  return (
    <div>
      <div className={styles.text}>
        <h1 className={styles.title}>{Text.ourTeam.title}</h1>
        <div className={styles.paragraph}>{Text.ourTeam.paragraph}</div>
      </div>
      <div className={styles.images}>
        {team.map((elemet, id) => {
          return (
            <div key={id}>
              <img className={styles.image} src={elemet.image} />
              <p className={styles.name}>{elemet.name}</p>
              <p className={styles.name}>{elemet.position}</p>
            </div>
          );
        })}
      </div>
      <div className={styles.text2}></div>
      <button onClick={buttonHandler} className={styles.seeButton}>
        {buttonText}
      </button>
    </div>
  );
};

export default OurTeamMobile;
