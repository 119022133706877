import React from "react";
import styles from "./styles.module.scss";

interface Props {
  source: string;
  title: string;
  text: string;
  storeString: string;
}

const DownloadButton = ({ source, title, text, storeString }: Props) => {
  return (
    <a className={styles.container} href={storeString}>
      <img className={styles.image} src={source} />
      <div className={styles.content}>
        <p className={styles.paragraph}>{text}</p>
        <h1 className={styles.title}>{title}</h1>
      </div>
    </a>
  );
};

export default DownloadButton;
