import React, { useState } from "react";

import styles from "./styles.module.scss";
import CarouselImage from "../../components/CarouselImage";
import { images } from "../../../core/constants/images";
import { carouselImages } from "../../../core/constants/arrays";
import { useSwipeable } from "react-swipeable";

const CarouselMobile = () => {
  const [screeenshots, setScreeenshots] = useState<string[]>(carouselImages);
  const [index, setIndex] = useState<number>(0);
  const rightArrowHandler = () => {
    setScreeenshots([...screeenshots, screeenshots[index]]);
    setIndex(index + 1);
  };
  const leftArrowHandler = () => {
    setIndex(index - 1);
  };
  const leftSwipe = useSwipeable({
    onSwipedLeft: () => rightArrowHandler(),
    onSwipedRight: () => leftArrowHandler(),

  });
  return (
    <>
      <div className={styles.title}>App Screenshots</div>
      <div className={styles.container}>
        <img
          className={styles.arrow_left}
          src={images.leftArrowCarousel}
          onClick={leftArrowHandler}
        />
        <div {...leftSwipe} className={styles.screenshots}>
          {screeenshots.map((img, id) => {
            return <CarouselImage key={id} src={img} index={index} />;
          })}
        </div>
        <img
          className={styles.arrow_right}
          src={images.rightArrowCarousel}
          onClick={rightArrowHandler}
        />
      </div>
    </>
  );
};

export default CarouselMobile;
