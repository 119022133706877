import React, { useState } from "react";

import styles from "./styles.module.scss";
import { images } from "../../../core/constants/images";
import OurTeamImage from "../../components/OurTeamImage";
import { ourTeamArray } from "../../../core/constants/arrays";
import { Text } from "../../../core/constants/strings";

const OurTeamTabletCarousel = () => {

  const [index, setIndex] = useState<number>(0);
  const [screenIndex, setScreenIndex] = useState<number>(0);

  const rightArrowHandler = () => {
    if(index < 4){
    setIndex(index + 1);
    setScreenIndex(screenIndex + 1);
    }
  };

  const leftArrowHandler = () => {
    if(index > 0){
    setIndex(index - 1);
    }
  };
     
  return (
    <>
      <div className={styles.title}>{Text.ourTeam.title}</div>
      <div className={styles.paragraph}>{Text.ourTeam.paragraph}</div>
      <div className={styles.container}>
        <img
          className={styles.arrow_left}
          src={images.leftArrowCarousel}
          onClick={leftArrowHandler}
        />
        <div className={styles.screenshots}>
          {ourTeamArray.map((element, id) => {
            return (
              <OurTeamImage
                key={id}
                image={element.image}
                name={element.name}
                position={element.position}
                index={index}
              />
            );
          })}
        </div>
        <img
          className={styles.arrow_right}
          src={images.rightArrowCarousel}
          onClick={rightArrowHandler}
        />
      </div>
      <div className={styles.dotsContainer}>
        <div className={styles.dots}></div>
        {[0, 1, 2, 3, 4].map((i) => (
          <div key={i} className={index === i ? styles.line : styles.dot}></div>
        ))}
      </div>
    </>
  );
};

export default OurTeamTabletCarousel;
