import React from "react";

import WelcomeTablet from "../../../tablet/blocks/WelcomeTablet/WelcomeTablet";
import HowItWorksTablet from "../../../tablet/blocks/HowItWorksTablet/HowItWorksTablet";
import DownloadTablet from "../../../tablet/blocks/DownloadTablet/DownloadTablet";
import FooterTablet from "../../../tablet/blocks/FooterTablet/Footer";
import OurTeamTabletCarousel from "../../../tablet/blocks/OurTeamTabletCarousel/OurTeamTabletCarousel";
import CarouselTablet from "../../../tablet/blocks/CarouselTablet/CarouselTablet";
import StartedTablet from "../../../tablet/blocks/StartedTablet/StartedTablet";

const FullPageTablet = () => {
  return (
    <>
      <WelcomeTablet />
      <StartedTablet />
      <CarouselTablet />
      <HowItWorksTablet />
      <OurTeamTabletCarousel />
      <DownloadTablet />
      <FooterTablet />
    </>
  );
};

export default FullPageTablet;
