import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";
import { images } from "../../../core/constants/images";
import { Text } from "../../../core/constants/strings";

const StartedMobile = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.container}>
      <img className={styles.image} src={images.startedImage} />
      <div className={styles.text}>
        <h1 className={styles.title}>{Text.started.title}</h1>
        <p className={styles.paragraph}>{Text.started.paragraph}</p>
        <Link className={styles.link} to="/story">
          <button onClick={scrollToTop} className={styles.seeButton}>
            SEE OUR FULL STORY
          </button>
        </Link>
      </div>
    </div>
  );
};

export default StartedMobile;
