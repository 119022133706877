import React from "react";

import styles from "./styles.module.scss";
interface Props {
  image: string;
  name: string;
  position: string;
  index: number;
}

const OurTeamCarousel = ({ image, index, name, position }: Props) => {
  return (
    <div className={styles.innerTeam} style={{ transform: `translateX(-${index * 100}%)` }}>
      <div className={styles.images}>
        <img className={styles.imageOurTeam} src={image} />
        <p className={styles.name}>{name}</p>
        <p className={styles.name}>{position}</p>
      </div>
    </div>
  );
};

export default OurTeamCarousel;
