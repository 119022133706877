import React from "react";

import styles from "./styles.module.scss";
interface Props {
  src: string;
  index: number;
  activeIndex: number;
  idd: number;
}

const CarouselImage = ({ src, index, activeIndex, idd }: Props) => {
  return (
      <div className={styles.inner} style={{ transform: `translateX(${index -1 === 0 && idd === 0 && index != 1 ? 1 * 80.2 : activeIndex === idd ? index * -80.2 : index * -100}%)` }}>
        <img className={index + 1 === idd ? styles.middlePhoto : activeIndex === idd ? styles.middlePhoto : styles.photo} src={src} />
      </div>
  );
};

export default CarouselImage;