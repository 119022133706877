import React, { useState } from "react";

import styles from "./styles.module.scss";
import { images } from "../../../core/constants/images";
import CarouselImage from "../../components/CarouselImage";
import { carouselImages } from "../../../core/constants/arrays";

const Carousel = () => {
  const [screeenshots, setScreeenshots] = useState<string[]>(carouselImages);
  const [index, setIndex] = useState<number>(0);
  const [screenIndex, setScreenIndex] = useState<number>(0);
  const rightArrowHandler = () => {
    setScreeenshots([
      ...screeenshots,
      screeenshots[screenIndex],
      screeenshots[screenIndex + 1],
      screeenshots[screenIndex + 2],
      screeenshots[screenIndex + 3]
    ]);
    setIndex(index + 1);
    setScreenIndex(screenIndex + 4)
  };
  const leftArrowHandler = () => {
    setIndex(index - 1);
  };
  return (
    <>
      <div className={styles.title}>App Screenshots</div>
      <div className={styles.container}>
        <img
          className={styles.arrow_left}
          src={images.leftArrowCarousel}
          onClick={leftArrowHandler}
        />
        <div className={styles.screenshots}>
          {screeenshots.map((img, id) => {
            return <CarouselImage key={id} src={img} index={index} />;
          })}
        </div>
        <img
          className={styles.arrow_right}
          src={images.rightArrowCarousel}
          onClick={rightArrowHandler}
        />
      </div>
    </>
  );
};

export default Carousel;
