import React from 'react';

import styles from "./styles.module.scss";
interface Props {
  src: string;
  index: number;
}

const CarouselImage = ({ src, index }: Props) => {

  return (
    <div>
      <div className={styles.inner} style={{ transform: `translateX(-${index * 400}%)` }}>
        <img className={styles.photo} src={src} />
      </div>
    </div>
  );
};

export default CarouselImage;