import React, { useState } from "react";

import styles from "./styles.module.scss";
import CarouselImage from "../../components/CarouselImage";
import { images } from "../../../core/constants/images";
import { carouselImages } from "../../../core/constants/arrays";

const CarouselTablet = () => {
  const [screeenshots, setScreeenshots] = useState<string[]>(carouselImages);
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const [index, setIndex] = useState<number>(0);
  const rightClickHandler = () => {
    if (index < 4) {
      setIndex(index + 1);
      setActiveIndex(activeIndex + 1);
    }
  };
  const leftClickHandler = () => {
    if(activeIndex > 0){
    if (index < 1) {
      setIndex(-1);
      setActiveIndex(activeIndex - 1);
    } else {
      setIndex(index - 1);
      setActiveIndex(activeIndex - 1);
    }
  }
  };
  return (
    <>
          <div className={styles.title}>App Screenshots</div>
    <div className={styles.container}>
      <img
        className={styles.arrow_left}
        onClick={leftClickHandler}
        src={images.leftArrowCarousel}
      />
      <div className={styles.screenshots}>
        {screeenshots.map((img, id) => {
          return (
            <CarouselImage key={id} idd={id} src={img} index={index} activeIndex={activeIndex} />
          );
        })}
      </div>
      <img
        className={styles.arrow_right}
        onClick={rightClickHandler}
        src={images.rightArrowCarousel}
      />
    </div>
    </>
  );
};

export default CarouselTablet;
