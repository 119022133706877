import React, { useState, useEffect } from "react";
import { isDesktop, isTablet } from "react-device-detect";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import OurStory from "../../desktop/blocks/OurStory/OurStory";
import OurStoryMobile from "../../mobile/blocks/OurStoryMobile/OurStoryMobile";
import OurStoryTablet from "../../tablet/blocks/OurStoryTablet/OurStoryTablet";
import FullPage from "./components/FullPage";
import FullPageMobile from "./components/FullPageMobile";
import FullPageTablet from "./components/FullPageTablet";

const renderRouter = (platform: string) => {
  if (platform === "desktop" && isDesktop)
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FullPage />} />
          <Route path="/story" element={<OurStory />} />
        </Routes>
      </BrowserRouter>
    );
  else if (platform === "tablet" || isTablet)
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FullPageTablet />} />
          <Route path="/story" element={<OurStoryTablet />} />
        </Routes>
      </BrowserRouter>
    );
  else return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<FullPageMobile />} />
          <Route path="/story" element={<OurStoryMobile />} />
        </Routes>
      </BrowserRouter>
  );
};

const Navigation = () => {
  const [platform, setPlatform] = useState<string>("desktop");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 992 && window.innerWidth > 576) setPlatform("tablet");
      else if (window.innerWidth < 576) setPlatform("mobile");
      else if (window.innerWidth > 992) setPlatform("desktop");
    }

    window.addEventListener("resize", handleResize);
  }, []);

  return <div>{renderRouter(platform)}</div>;
};

export default Navigation;
