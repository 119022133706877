export const Text = {
  welcome: {
    logoText: "BookTrade",
    content: {
      title: "Welcome to Booktrade",
      paragraph:
        "Do you love your books? Then let them go! Sharing is caring, so why not trade your favourite books with someone who’ll love every single page of them, just like you did?",
      titleWrapWelcome: "Welcome",
      titleWrapToBooktrade: "to Booktrade"
    },
    buttons: {
      seeButton: "SEE APP VIDEO",
      appleButton: {
        title: "App Store",
        text: "Download on the"
      },
      googlePlayButton: {
        title: "Google Play",
        text: "Download on the"
      }
    }
  },
  ourTeam: {
    title: "Meet Our Team & Their Booktrade Experience",
    paragraph:
      "A committed team stands behind each product, interpreting its vision and bringing it to life. We, the creators, strategists, and developers, unite with a common objective: enhancing the society we inhabit. Presently, our focus is on BookTrade, the platform that unites all book enthusiasts."
  },
  started: {
    title: "How It All Started?",
    paragraph:
      "How do all great ideas usually start? With a strange thought: “I wonder what other people feel about my favourite book. Do they feel the same way? Or maybe they’ve never heard of it. And maybe I’ve never heard of theirs.” so we decided to launch Booktrade: a way to connect with other book lovers, to join a community of avid readers who cherish their books so much they want to share them with others."
  },
  footer: {
    title: "Let’s read!",
    appName: "BookTrade",
    webSite: "www.nomorecoffeeplease.com",
    phoneNumber: "+40742826647",
    eMail: "contact@nomorecoffeeplease.com"
  },
  download: {
    title: "Download App Today",
    paragraph:
      "Why wait when there’s so much waiting for you out there? Embark yourself on a fun journey and become part of a cool community of book lovers!"
  },
  howItWorks: {
    title: "How It Works?",
    paragraph:
      "We designed a process as easy as possible to be able to quickly trade your books: Create an account, choose your favorite genres, edit your user profile, add the books you want to trade, strat discovering the books you want, click the Like button if you would like it in your library, start chatting and establish a trade meeting."
  },
  ourTeamMobile: {
    see: "SEE ALL",
    hide: "HIDE"
  },
  ourStory: {
    title: "Our full story",
    paragraph:
      "It all started during the Covid-19 pandemic. A small team of people thought about what would be the next best thing for society. We noticed a couple of issues, and one of them was that Book Lovers preferred exchanging books through Facebook Groups. We wanted to improve that, and with a little help from the ”Great Cat with a coffee cup” we managed to make it a reality. The project started two years ago with a ton of enthusiasm, as it was also our first one. Fast forward a couple of years, the team has changed, peopel grew, the project grew, and now we're here: The first Beta Launch for a platform that allows exchanging books to be easy, trust free and reglemented. Of course, you might wonder: why would I ever want to exchange my books? Well, because let's be real: When will you read it again? Probably in a couple of years or so, and if not, well good luck with the other 50+ books in your library just sitting there. So, here we are, a team full of really passionate people, waiting for you to try their first app."
  },
  downloadLinks: {
    google: "https://play.google.com/store/apps/details?id=com.booktrade&pcampaignid=web_share",
    apple: "https://apps.apple.com/ro/app/booktrade/id1594804174"
  }
};
