import React, { useRef, useState } from "react";

import styles from "./styles.module.scss";
import { images } from "../../../core/constants/images";
import { Text } from "../../../core/constants/strings";
import trailer from "../../../core/videos/trailer.mp4";

const HowItWorks = () => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const vidRef = useRef<any>(null);
  const [videoState, setVideoState] = useState('playing')
  const handlePlayVideo = () => {
    if (videoState === "paused") {
      vidRef.current.play();
      setVideoState("playing");
    } else {
      vidRef.current.pause();
      setVideoState("paused");
    }
  };

  return (
    <div id="video" className={styles.container}>
      <div className={styles.title}>{Text.howItWorks.title}</div>
      <div className={styles.paragraph}>{Text.howItWorks.paragraph}</div>
      <div
        className={styles.content}
        style={{ backgroundImage: `url(${images.howItWorksBackground})` }}>
        <div className={styles.imageContainer}>
          <img src={images.howItWorksPhone} className={styles.image} />
        </div>
        <div className={styles.videoContainer}>
          <video
            onClick={handlePlayVideo}
            ref={vidRef}
            className={styles.video}
            autoPlay={true}
            playsInline
            muted
            loop={true}>
            <source src={trailer} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          {videoState === "paused" && (
            <img onClick={handlePlayVideo} src={images.playButton} className={styles.playButton} />
          )}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
