import { ourTeam } from "../constants/images";
import { carousel } from "./images";

export const ourTeamArray = [
  { image: ourTeam.andreiStoica, name: "Andrei Stoica", position: "CEO" },
  { image: ourTeam.norbert, name: "Norbi Postol", position: "CTO" },
  { image: ourTeam.adrian, name: "Andrian Dobrican", position: "UI/UX Design" },
  { image: ourTeam.andreiPop, name: "Andrei Pop", position: "Developer" },
  { image: ourTeam.marcel, name: "Marcel Marc", position: "Developer" },
  { image: ourTeam.sylvia, name: "Incze Sylvia", position: "UI/UX Design" },
  { image: ourTeam.sorin, name: "Sorin Șteti", position: "QA" },
  { image: ourTeam.vlad, name: "Vlad Dunca", position: "Developer" },
  { image: ourTeam.bogdan, name: "Bogdan Ținta", position: "Developer" },
  { image: ourTeam.alin, name: "Alin Bivolan", position: "Developer" },
  { image: ourTeam.alexBirta, name: "Alex Birta", position: "Developer" },
  { image: ourTeam.alexPetrusca, name: "Alex Petrușcă", position: "Developer" },
  { image: ourTeam.mihai, name: "Mihai Venczel", position: "Developer" },
  { image: ourTeam.catalin, name: "Cătălin Racz", position: "Developer" },
];
export const ourTeamArray2 = [
  { image: ourTeam.andreiStoica, name: "Andrei Stoica", position: "CEO" },
  { image: ourTeam.norbert, name: "Norbi Postol", position: "CTO" },
  { image: ourTeam.adrian, name: "Andrian Dobrican", position: "UI/UX Design" },
  { image: ourTeam.andreiPop, name: "Andrei Pop", position: "Developer" }
];

export const carouselImages = [
  carousel.carouselLogin,
  carousel.carouselGenres,
  carousel.carouselHarryPotter,
  carousel.carouselMyBooks,
  carousel.carouselLogin,
  carousel.carouselGenres,
  carousel.carouselHarryPotter,
  carousel.carouselMyBooks,
];

export const ourTeamExperience = [
  {
    title: "Management",
    paragraph:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
  },
  {
    title: "Development",
    paragraph:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
  },
  {
    title: "UI/UX Desing",
    paragraph:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
  }
];
